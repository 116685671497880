import { Injectable } from "@angular/core";

@Injectable({providedIn: "root"})
export class FrontendEnvironmentService {

    isProductionEnvironment(): boolean {
        return document.body.classList.contains("environment-production");
    }

    isFinnpilotQaEnvironment(): boolean {
        return window.location.hostname === 'testi.pilotweb.fi';
    }
}
